<template>
  <div
    style="padding-top: 10px"
    class="tab-new-ui"
    v-if="selectedVariables && selectedVariables.length"
  >
    <hr />
    <b-card class="">
      <div :class="$route.name === 'alert-create' ? 'min-card-54' : ''">
        <div class="alert-view mb-2" v-if="$route.name === 'alert-create'">
          <app-collapse>
            <app-collapse-item
              :title="eventData && eventData.name ? eventData.name : ''"
              :isVisible="false"
            >
              <div class="text-primary">
                {{ eventData && eventData.description }}
              </div>
            </app-collapse-item>
          </app-collapse>
        </div>
        <h5>{{ $t("alert.ConfigureRequiredInformation") }}</h5>
        <validation-observer ref="refVariables">
          <b-row class="mt-2">
            <b-col sm="12">
              <!-- <v-select id="vue-select" v-model="selected" :roles="roles" /> -->
              <ParameterAlertComponents
                component_key="alert"
                :parameters="selectedVariables"
                :isEditable="isEditable"
                v-model="selectedVariables"
                @validationError="showValidationError"
              />
              <!-- <b-row>
                <b-col
                  sm="6"
                  v-for="alertVariables in selectedVariables"
                  :key="alertVariables.field"
                >
                  <div
                    v-if="
                      [
                        'string',
                        'number',
                        'boolean',
                        'timestamp',
                        'datetime'
                      ].includes(alertVariables.data_type.toLowerCase())
                    "
                  >
                    <b-form-group
                      :label="
                        alertVariables.label
                          ? alertVariables.label
                          : alertVariables.name &&
                            formattedString(alertVariables.name)
                      "
                      :description="alertVariables.label_description"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Name"
                        vid="Name"
                        :rules="{
                          required: true
                        }"
                      >
                        <div
                          v-if="
                            ['boolean'].includes(
                              alertVariables.data_type.toLowerCase()
                            )
                          "
                        >
                          <b-form-checkbox
                            v-model="alertVariables.value"
                            style="top: 10px"
                            :disabled="!isEditable"
                          >
                          </b-form-checkbox>
                        </div>
                        <div
                          v-if="
                            ['timestamp', 'datetime'].includes(
                              alertVariables.data_type.toLowerCase()
                            )
                          "
                        >
                          <div class="flat-picker-date">
                            <flat-pickr
                              @on-close="DateTimeFormat(alertVariables.name)"
                              :ref="`dateTime_${alertVariables.name}`"
                              v-model="alertVariables.value"
                              :disabled="!isEditable"
                              class="form-control date-time-input"
                              :config="{
                                enableTime: true,
                                time_24hr: true
                              }"
                            />
                            <div class="flat-picker-icon">
                              <a
                                ><feather-icon
                                  icon="XIcon"
                                  size="24"
                                  @click="clearDate(alertVariables.name)"
                              /></a>
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="
                            ['string', 'number'].includes(
                              alertVariables.data_type.toLowerCase()
                            )
                          "
                        >
                          <b-input-group
                            class="input-group-merge"
                            :class="errors.length > 0 ? 'is-invalid' : null"
                          >
                            <b-form-input
                              class="d-inline-block open-tel-parama"
                              :state="errors.length > 0 ? false : null"
                              v-model="alertVariables.value"
                              :disabled="!isEditable"
                              @input="
                                getVariableConfigInfo(
                                  $refs.refVariables,
                                  selectedVariables
                                )
                              "
                              :type="
                                alertVariables.data_type.toLowerCase() ==
                                'number'
                                  ? 'number'
                                  : 'text'
                              "
                            />
                          </b-input-group>
                        </div>
                      </validation-provider>
                    </b-form-group>
                  </div>
                </b-col>
              </b-row> -->

              <!-- {{ sensorInfo.fields }} -->
            </b-col>
          </b-row>
        </validation-observer>
      </div>
    </b-card>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ParameterAlertComponents from "@/views/DynamicComponents/ParameterAlertComponents.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
// import flatPickr from "vue-flatpickr-component";

import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BForm,
  BTab,
  BTabs,
  BCard,
  BMedia,
  BFormInvalidFeedback,
  VBTooltip,
  BInputGroupAppend,
  BInputGroup
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BCardCode,
    BTab,
    BTabs,
    BCard,
    BMedia,
    BFormInvalidFeedback,
    AppCollapse,
    AppCollapseItem,
    VBTooltip,
    BInputGroupAppend,
    BInputGroup,
    ParameterAlertComponents
    // flatPickr
  },
  data() {
    const { refFormObserver, getValidationState } = formValidation();
    return {
      getValidationState,

      selectedVariables: [],
      selectedVariables1: [],
      showLoading: false
    };
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip
  },

  mounted() {
    this.selectedVariables = (this.eventData && this.eventData.variables) || [];

    this.selectedVariables.map((e) => {
      const va =
        this.alertInfo &&
        this.alertInfo.variables &&
        this.alertInfo.variables.length &&
        this.alertInfo.variables.find((v) => v.name == e.name);
      e.value = (va && va.value) || "";
    });
    if (this.$refs && this.$refs.refVariables) {
      setTimeout(() => {
        this.getVariableConfigInfo(
          this.$refs.refVariables,
          this.selectedVariables
        );
      }, 100);
    }
  },
  props: ["eventData", "getVariableConfigInfo", "alertInfo", "isEditable"],
  watch: {
    selectedVariables: {
      handler(val) {
        this.getVariableConfigInfo(
          this.$refs.refVariables,
          this.selectedVariables
        );
      },
      deep: true
    }
  },

  methods: {
    onCloseDeviceSensors() {
      this.onCloseDeviceAddSensors();
    },
    showValidationError(error) {
      // this.validationError = error;
    },
    clearDate(field) {
      if (
        this.$refs["dateTime_" + field] &&
        this.$refs["dateTime_" + field].length
      ) {
        this.$refs["dateTime_" + field][0].fp.clear();
      }
    },

    handleError(error) {
      return this.formattedErrorMsg(error);
    },
    DateTimeFormat(filed, value) {
      this.sensorInfo.fields[filed].value = new Date(value);
    },

    formattedString(filed) {
      return this.formattedString(filed, "_", " ");
    }
  }
};
</script>
<style lang="scss">
.form-control[readonly] {
  background-color: white;
}
.alert-view {
  min-width: 100%;
  .collapse-default .card {
    border: 1px solid var(--primary);
    border-radius: 8px;
    background: var(--rgb-primary-12);
    .collapse-title {
      color: var(--primary);
    }
  }
}
// .min-card-54 {
//   min-height: 52vh;
// }
</style>
<style lang="scss">
// .tab-new-ui {
//   height: calc(100vh - 220px);
//   overflow-y: auto;
//   overflow-x: hidden;
//   margin-bottom: 10px;
// }
</style>
